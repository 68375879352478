import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: fit-content;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff7070;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }

    //  表用のCSS
    table {
      width: 100%;
      margin-bottom: 15px;


      td {
        padding: 5px;
        border: solid 1px rgba(200, 200, 200, 1);
        vertical-align: top;
      }
    }
  }

}

${BreakPoints.large} {
}
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0073 = (props: Props): JSX.Element => {
  const routeParam = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0006/'
  )
  const routeParam2 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0069/'
  )
  const routeParam3 = useRouteParam('https://www.kintaicloud.jp/')
  return (
    <Wrapper>
      <ColumnH1 label="変形労働時間制とは？勤怠管理の効率的な方法を解説" />
      {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
      <p className="p-txt-date">2024.1.22</p>
      <section>
        {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
        <img
          src="/images/column/details/c0073.jpg"
          alt="キンクラコラム画像73"
        />
        <p>
          働き方改革などの影響により、変形労働時間制を導入する企業も増えてきました。
          <br />
          そのため、自社でも変形労働時間制の導入を検討している方も多いでしょう。
          <br />
          しかし、「そもそも変形労働時間制とは何か」「どのようなメリットやデメリットがあるのか」また「
          <span>どのような方法で勤怠管理を行えばいいのかわからない</span>
          」とお困りの方もいるでしょう。
          <br />
          そこで本記事では、「変形労働時間制とは何か」という概要から、メリットやデメリットや勤怠管理をするにはどのような方法が良いのか解説します。
          <br />
          本記事を読むことで、変形労働時間制に最適な勤怠管理の方法がわかるので、ぜひ一読ください。
        </p>
        <div id="toc1">
          <h1>変形労働時間制とは</h1>
        </div>
        <p>まずは、「変形労働時間制とは何か」について解説していきます。</p>
        <div id="toc1-1">
          <h2>1カ月や1年単位で労働時間を調整する制度</h2>
        </div>
        <p>
          変形労働時間制とは、一定の期間で労働時間を調整する制度です。
          <br />
          業務の繁忙期や閑散期に応じて、
          <span>労働時間を柔軟に調整できます</span>
          。
          <br />
          たとえば、繁忙期に長時間労働となる日がある一方で、閑散期には短時間労働となる日があるという場合に労働時間の調整が可能です。
        </p>
        <p>
          変形労働時間制についてさらに詳しく知りたい人はこちら
          <br />
          <a href={routeParam} className="column-detail-link">
            参照記事：通常の労働時間制（固定労働時間制度）と変形労働時間制とは？
          </a>
        </p>
        <div id="toc1-2">
          <h2>フレックスタイム制との違いとは？</h2>
        </div>
        <p>
          よく似ているものとして「フレックスタイム制」があります。
          <br />
          フレックスタイム制と変形労働時間制の主な違いは、「
          <span>自由に設定できる労働時間の範囲</span>」です。
          <br />
          フレックスタイム制では、全員が出勤しなければならない時間帯であるコアタイムを除いて、始業時間や終業時間を自由に設定できます。
          <br />
          一方、変形労働時間制では、労働時間の長さを一定の期間で調整します。
        </p>
        {/** ここに9パターンの広告設定する */}
        {props.no === 0 && <Introduction004 />}
        {props.no === 1 && <Introduction005 />}
        {props.no === 2 && <Introduction006 />}
        {props.no === 3 && <Introduction007 />}
        {props.no === 4 && <Introduction008 />}
        {props.no === 5 && <Introduction009 />}
        {props.no === 6 && <Introduction010 />}
        {props.no === 7 && <Introduction011 />}
        {props.no === 8 && <Introduction012 />}
        <div id="toc2">
          <h1>変形労働時間制のメリットとデメリット</h1>
        </div>
        <p>
          変形労働時間制の導入を検討しているのであれば、メリットやデメリットについて理解しておく必要があります。
          <br />
          ここでは、変形労働時間制のメリットとデメリットについて解説します。
        </p>
        <div id="toc2-1">
          <h2>変形労働時間制のメリット</h2>
        </div>
        <p>変形労働時間制には、以下のようなメリットがあります。</p>
        <p>
          <h3>残業代の削減が期待できる</h3>
          変形労働時間制を導入し、繁忙期と閑散期の労働時間を調整することによって、繁忙期に勤務時間が増加しても時間外労働として取り扱わないといった対応が可能です。
          <br />
          それによって、<span>残業代の削減をすることが可能</span>になります。
        </p>
        <p>
          <h3>業務効率化につながる</h3>
          変形労働時間制を導入することで、繁忙期にリソースを集中させることが可能になり、業務の効率化につながります。
          <br />
          また、閑散期には労働時間を減らすことで、
          <span>従業員の休息時間を確保する</span>
          ことができるというメリットもあります。
          <br />
          集中すべき仕事でパフォーマンスを最大化し、閑散期にはしっかり休むことで、メリハリのある勤務が可能となり、組織全体の生産性を向上させることにつながります。
        </p>
        <p>
          <h3>会社のイメージアップが図れる</h3>
          近年は働き方改革の影響もあり、「働きやすい環境」の重要性は増しています。
          <br />
          そうした中で、柔軟な勤務時間を提唱している企業として社外へアピールすることができ、
          <span>会社のイメージアップ</span>につながる可能性があります。
        </p>
        <div id="toc2-2">
          <h2>変形労働時間制のデメリット</h2>
        </div>
        <p>一方で、変形労働時間制には以下のようなデメリットもあります。</p>
        <p>
          <h3>労働者の勤怠管理が複雑になる</h3>
          変形労働時間制の導入は、労働時間が日々一定ではなくなるため、
          <span>労働者の勤怠管理が複雑</span>になります。
          <br />
          特に、人事担当者にとっては、労働時間の計算や記録が手間となる場合があります。
        </p>
        <p>
          <h3>残業の管理でトラブル発生の可能性がある</h3>
          繁忙期と閑散期の労働時間の調整が特徴である変形労働時間制の導入は、
          <span>残業の管理が難しくなる</span>場合があります。
          <br />
          そのため、しっかりと管理しないと、残業時間の計算ミスなどが起こりやすくなり労働者と会社との間でトラブル発生の要因につながる可能性があります。
          <br />
          <br />
          このように、変形労働時間制にはさまざまなメリットやデメリットがあるため、変形労働時間制を導入する際には以上の内容を踏まえて検討してみましょう。
        </p>
        <div id="toc3">
          <h1>変形労働時間制はExcel(エクセル)で勤怠管理することは難しい </h1>
        </div>
        <img
          src="/images/column/details/c0073_g01.jpg"
          alt="キンクラコラム画像73"
        />
        <p>
          勤怠管理をExcelで行っている企業も多いでしょう。
          <br />
          しかし、変形労働時間制を導入した場合、Excelでの勤怠管理は困難です。
          <br />
          では、なぜExcelで管理することは難しいのか、その理由について解説していきます。
        </p>
        <div id="toc3-1">
          <h2>Excelで管理するには手間とコストがかかる</h2>
        </div>
        <p>
          Excelで勤怠管理を行う場合、各従業員の勤怠データを
          <span>ひとつひとつ手入力</span>する必要があります。
          <br />
          これは、時間とコストがかかるだけでなく、データの入力ミスや集計ミスを引き起こす可能性があります。
        </p>
        <div id="toc3-2">
          <h2>人為的ミスが起きる可能性がある</h2>
        </div>
        <p>
          Excelで勤怠管理を行う場合に発生しやすいミスの一つです。
          <br />
          たとえば、発生しやすいミスの一例としてデータ入力ミスや集計ミスが挙げられます。
          <br />
          これらのミスは、労働時間の計算誤差を引き起こし、
          <span>労働者の給与に影響</span>を及ぼす可能性があります。
        </p>
        <div id="toc3-3">
          <h2>管理方法を一元化する必要がある</h2>
        </div>
        <p>
          変形労働時間制を導入することにより、
          <span>労働時間の管理方法を一元化する必要</span>
          があります。しかし、Excelでは複数のシートやファイルを一元管理することは難しく、これが労働時間の管理を複雑にする一因となります。
          <br />
          <br />
          以上のように変形労働時間制をExcelで管理することは困難です。
          <br />
          では、どのような方法で勤怠管理を行えばよいのか、次の項目で詳しく解説します。
        </p>
        <a href={routeParam2} className="column-detail-link">
          参照記事：手動での勤怠管理からの脱却！無料の勤怠管理システムのメリットとは？{' '}
        </a>
        <div id="toc4">
          <h1>
            変形労働時間制の企業には勤怠管理システム「キンクラ」の導入がおすすめ
          </h1>
        </div>
        <p>
          変形労働時間制は、その柔軟性から多くの企業で採用されていますが、管理には時間がかかります。
          <br />
          Excelでの管理は手間とコストがかかるだけでなく、人為的ミスが生じる可能性もあります。そのため、勤怠管理システムの導入が有効です。
          <br />
          とはいえ、勤怠管理システムを導入してみたところ自社の環境には合わず、余計なコストがかかってしまうといったリスクがあります。
          <br />
          <span>そこで、お勧めなのが「キンクラ」です。</span>
          <br />
          「キンクラ」は変形労働時間制にも対応しており、「無料トライアル」を実施しています。
          <br />
          もし、勤怠管理システムの導入を検討しているならば、「キンクラ」を試してみませんか？
        </p>
        <a href={routeParam3} className="column-detail-link">
          ⇒ 勤怠管理システム「キンクラ」について詳しく知りたい方はここをクリック
        </a>
      </section>
    </Wrapper>
  )
}

export default ColumnDetailInner0073
