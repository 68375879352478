import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnDetailInner0073 from '../../../components/molecules/columnDetailInner0073'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import Conclusion001 from '../../../components/molecules/Conclusion001'
import Conclusion002 from '../../../components/molecules/Conclusion002'
import Conclusion003 from '../../../components/molecules/Conclusion003'
import Conclusion004 from '../../../components/molecules/Conclusion004'
import Conclusion005 from '../../../components/molecules/Conclusion005'
import Conclusion006 from '../../../components/molecules/Conclusion006'
import Conclusion007 from '../../../components/molecules/Conclusion007'
import Conclusion008 from '../../../components/molecules/Conclusion008'
import Conclusion009 from '../../../components/molecules/Conclusion009'
import CarouselBanner from '../../../components/molecules/carouselBanner'
import PopupBanner from '../../../components/molecules/popupBanner'
import ColumnAuthor004 from '../../../components/molecules/columnAuthor004'
import UsefulFunctionLayout021 from '../../../components/molecules/usefulFunctionLayout021'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0073 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title: '変形労働時間制とは？勤怠管理の効率的な方法を解説',
    },
  ]

  // 0~8ランダム数字を保存
  const [no, setNo] = useState(-1)
  useEffect(() => {
    setNo(Math.floor(Math.random() * 9))
  }, [])

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="変形労働時間制とは？勤怠管理の効率的な方法を解説"
        description="働き方改革などの影響により、変形労働時間制の導入を検討されている企業様にとって、概要をはじめ、メリットやデメリット、そして最適な勤怠管理方法を解説します。変形労働時間制に最適な勤怠管理システムについてもご紹介していますので、参考にしてください。"
        ogUrl="https://kintaicloud.jp/column/details/C0073/"
        ogType="article"
        ogTitle="変形労働時間制とは？勤怠管理の効率的な方法を解説"
        ogDescription="働き方改革などの影響により、変形労働時間制の導入を検討されている企業様にとって、概要をはじめ、メリットやデメリット、そして最適な勤怠管理方法を解説します。変形労働時間制に最適な勤怠管理システムについてもご紹介していますので、参考にしてください。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0073.jpg"
      />
      <ColumnDetailsBody>
        {/** ポップアップバナー */}
        <PopupBanner />
        {/** 記事本体 */}
        <ColumnDetailInner0073 no={no} />

        {/** ここに9パターンの広告設定する */}
        {no === 0 && <Conclusion001 />}
        {no === 1 && <Conclusion002 />}
        {no === 2 && <Conclusion003 />}
        {no === 3 && <Conclusion004 />}
        {no === 4 && <Conclusion005 />}
        {no === 5 && <Conclusion006 />}
        {no === 6 && <Conclusion007 />}
        {no === 7 && <Conclusion008 />}
        {no === 8 && <Conclusion009 />}

        {/** オーサ情報 */}
        <ColumnAuthor004 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout021 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
        {/** カルーセルバナー */}
        <CarouselBanner />
        {/** 関連記事 */}
        {/* <ColumnRelationCards0073 /> */}
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0073

export const pageQuery = graphql`
  query C0073 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
